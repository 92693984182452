import Roulette from '../Roulette';
import MoreRow from '../MoreRow';

function Home() {
    return (
        <>
          <Roulette />
          <MoreRow />
        </>
    );
}

export default Home